<template>
    <r-e-dialog title="详情房间号" :visible.sync="dialogVisible" width="650px">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish">
            <el-form-item label="" prop="intermediateUuid" style="font-size: 100px;">
                <div v-for="(item,index) in roomList" :key="index">
                    <div>{{ item.apartmentName }}</div>
                </div>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {issueRoomList} from "@/api/lock-permission";

export default {
    name: "go-details",
    data() {
        return {
            formPublish: {},
            dialogVisible: false,
            roomList: [],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            status: null,
        }
    },
    async created() {
    },
    methods: {
        async openDialog(row) {
            const {uuid, status} = row;
            this.uuid = uuid;
            this.status = status;
            await this.allocateNolist(uuid)
            this.dialogVisible = true;
        },
        async allocateNolist(uuid) {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading(loadingOptions);
            const res = await issueRoomList({uuid}).finally(() => loading.close());
            if (res) {
                let {list} = res;
                // if (that.status !== 0) {list = list.filter(({status}) => status === 1);}
                that.roomList = list;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item__label {
    font-size: 24px;
    color: black;
}

.el-select {
    width: 100%;
}

.flex {
    display: flex;

    /deep/ .el-form-item {
        flex: 1;
        white-space: nowrap;
    }

    /deep/ .tag-group {
        white-space: normal;

        .el-tag {
            margin: 0 VW(5px) VH(5px) 0;
            cursor: pointer;
        }
    }

    /deep/ .samplePhotos {
        white-space: normal;
    }
}
</style>
